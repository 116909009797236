<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px` || '24px'" :width="`${width}px` || '24px'">
    <path
      d="M15.4148 7.34954C15.1254 7.34969 14.845 7.4502 14.6213 7.63394C14.3977 7.81768 14.2447 8.07327 14.1883 8.35718C14.132 8.64108 14.1759 8.93573 14.3124 9.19093C14.449 9.44612 14.6698 9.64607 14.9373 9.75671C15.2048 9.86735 15.5023 9.88183 15.7792 9.79769C16.0562 9.71354 16.2954 9.53598 16.4561 9.29525C16.6168 9.05453 16.689 8.76553 16.6605 8.47749C16.6321 8.18946 16.5046 7.92021 16.2998 7.71563C16.1837 7.59929 16.0458 7.50705 15.8939 7.44422C15.742 7.38139 15.5792 7.34921 15.4148 7.34954Z"
      :fill="color"
    />
    <path
      d="M22.3801 2.34656C22.3799 2.34517 22.3799 2.34374 22.3801 2.34234C22.3404 2.16859 22.253 2.00936 22.1277 1.88264C22.0024 1.75592 21.8442 1.66673 21.6709 1.62516C20.274 1.28438 18.0765 1.64766 15.6413 2.62266C13.1869 3.60703 10.8873 5.06016 9.33288 6.61734C8.83467 7.11291 8.37181 7.64281 7.94773 8.20313C6.90101 8.15625 5.97898 8.30531 5.20226 8.64422C2.4957 9.83531 1.72273 12.8836 1.51695 14.1352C1.48717 14.313 1.49883 14.4953 1.55102 14.6679C1.6032 14.8405 1.69451 14.9987 1.81784 15.1302C1.94116 15.2618 2.09318 15.3631 2.26205 15.4262C2.43092 15.4894 2.6121 15.5128 2.79148 15.4945H2.79757L5.81257 15.1655C5.81632 15.2039 5.82054 15.2391 5.82382 15.2714C5.86254 15.6388 6.02639 15.9817 6.28788 16.2427L7.75929 17.715C8.01985 17.9769 8.36269 18.1409 8.73007 18.1795L8.83085 18.1903L8.50273 21.2016V21.2077C8.48596 21.3698 8.50321 21.5337 8.55339 21.6889C8.60357 21.844 8.68557 21.9869 8.79415 22.1086C8.90272 22.2302 9.03548 22.3278 9.18394 22.3952C9.3324 22.4626 9.49329 22.4983 9.65632 22.5C9.72149 22.5001 9.78656 22.4947 9.85085 22.4841C11.1094 22.2816 14.1563 21.518 15.3441 18.7969C15.6802 18.0253 15.8288 17.107 15.7871 16.0622C16.3499 15.639 16.8818 15.1761 17.3785 14.677C18.9455 13.118 20.4034 10.837 21.3774 8.41828C22.3473 6.01078 22.7124 3.79688 22.3801 2.34656ZM17.3616 10.5441C16.9765 10.9296 16.4856 11.1923 15.9511 11.2988C15.4167 11.4053 14.8626 11.351 14.3591 11.1425C13.8555 10.9341 13.4251 10.581 13.1223 10.1279C12.8194 9.67482 12.6578 9.14209 12.6578 8.59711C12.6578 8.05213 12.8194 7.5194 13.1223 7.0663C13.4251 6.61321 13.8555 6.26011 14.3591 6.05169C14.8626 5.84327 15.4167 5.78888 15.9511 5.89541C16.4856 6.00194 16.9765 6.2646 17.3616 6.65016C17.6198 6.90444 17.8248 7.20752 17.9647 7.54177C18.1047 7.87601 18.1767 8.23475 18.1767 8.59711C18.1767 8.95947 18.1047 9.31821 17.9647 9.65245C17.8248 9.9867 17.6198 10.2898 17.3616 10.5441Z"
      :fill="color"
    />
    <path
      d="M7.59047 18.1144C7.40909 18.0919 7.22573 18.1364 7.07485 18.2395C6.77531 18.4444 6.47438 18.6469 6.17063 18.8442C5.5561 19.2436 4.8211 18.5423 5.18625 17.9067L5.75578 16.9223C5.83721 16.8032 5.88255 16.6632 5.88636 16.5189C5.89018 16.3747 5.85231 16.2324 5.77731 16.1092C5.7023 15.9859 5.59333 15.8869 5.46347 15.824C5.3336 15.7612 5.18836 15.7371 5.04516 15.7547C4.41305 15.8346 3.82549 16.1225 3.375 16.5731C3.20344 16.7452 2.68172 17.2673 2.40094 19.2572C2.32102 19.8287 2.27064 20.404 2.25 20.9808C2.24744 21.0809 2.26494 21.1805 2.30148 21.2737C2.33802 21.3669 2.39286 21.4519 2.46276 21.5236C2.53266 21.5953 2.61621 21.6522 2.70848 21.6911C2.80075 21.73 2.89987 21.75 3 21.75H3.01875C3.59594 21.7296 4.1717 21.6795 4.74375 21.6C6.73453 21.3187 7.25672 20.7966 7.42828 20.625C7.88095 20.1743 8.16818 19.584 8.24344 18.9497C8.26754 18.7523 8.2123 18.5535 8.08986 18.3969C7.96742 18.2402 7.7878 18.1386 7.59047 18.1144Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
